import * as bootstrap from 'bootstrap'

export function Disclaimer(modalId, localStorageKey) {
    const element = document.getElementById(modalId)
    if (!element) {
        return
    }

    const modal = new bootstrap.Modal(element, {keyboard: false, backdrop: 'static'})

    // Check if disclaimer has been accepted
    if (localStorage.getItem(localStorageKey) !== 'accepted') {
        modal.show()
    }

    element.addEventListener('hide.bs.modal', () => {
        localStorage.setItem(localStorageKey, 'accepted')
    })

    element.addEventListener('hidePrevented.bs.modal', () => {
        // Allow normal dismiss when already accepted
        if (localStorage.getItem(localStorageKey) === 'accepted') {
            modal.hide()
        }
    })
}