export default {
    WMS_URL: import.meta.env.VITE_WMS_URL,
    WFS_URL: import.meta.env.VITE_WFS_URL,
    COLORS: {
        'primary': '#eb5a6d',
        'primary-light': '#eb98a3',
        'secondary': '#679fa8',
        'secondary-light': '#9bc7d0',
        'tertiary': '#ffe600',
    },
    DEFAULT_VIEW_OPTIONS: {
        center: [914485, 5940137],
        extent: [613007, 5701224, 1217984, 6125048],
        zoom: 8,
        maxZoom: 20,
    },
    NOMINATIM_URL: 'https://nominatim.openstreetmap.org/search',
}