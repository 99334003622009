import * as bootstrap from 'bootstrap'

/**
 * - ADdress
 * - Verfügbarkeit
 * - POP
 * - Ready Date / Bestellbar ab. -> GK1/11
 * - Quality
 *  - G1,GK11 -> Bestellbar ab
 *  - G2,GK12 -> Mutmasslich bestellbar ab
 *  - G3,GK13 -> Voraussichtlich bestellbar ab
 *  - G4,GK14 -> Vermutlich bestellbar ab
 *
 *  => Datumsverbindlichkeit 90%/70%/50%/30%
 *
 */


/*
Object Data:

{
  "geometry": {
    "disposed": false,
    "pendingRemovals_": null,
    "dispatching_": null,
    "listeners_": {
      "change": [
        null
      ]
    },
    "revision_": 1,
    "ol_uid": "724",
    "values_": null,
    "extent_": [
      1000491.953976,
      5900562.539075,
      1000491.953976,
      5900562.539075
    ],
    "extentRevision_": 1,
    "simplifiedGeometryMaxMinSquaredTolerance": 0,
    "simplifiedGeometryRevision": 0,
    "layout": "XY",
    "stride": 2,
    "flatCoordinates": [
      1000491.953976,
      5900562.539075
    ]
  },
  "id": "RFO4180",
  "nc_sad_device_loc": "TRU",
  "nc_sad_netw_code": "TRU",
  "nc_sad_netw_loc_code": "660",
  "priority": 3,
  "sad_alo_ind": false,
  "sad_city_name": "Trun",
  "sad_com_dt": null,
  "sad_gwr_building_egid": 191971881,
  "sad_house_num": "20",
  "sad_isr_dt": "2023-06-29",
  "sad_municipality_name": "Trun",
  "sad_postal_code": 7166,
  "sad_residence_name": null,
  "sad_street_name": "Splecs, via",
  "sad_target_dt": null,
  "sad_target_dt_quality_id": null,
  "scs_site_name": "660TRU",
  "site_id": null,
  "site_name": null,
  "site_service_start": null,
  "site_status": null,
  "source": "ready-for-order",
  "state": "h7_rfo",
  "hover": 1,
  "clicked": 1
}
 */



function formatDateYMD(date) {
    if (!date) {
        return ''
    }
    return date.toISOString().substring(0, 10)
}

class PointData {
    constructor(feature) {
        /** @type {string} */
        this.id = feature.get('id')
        /** @type {number} */
        this.priority = feature.get('priority')
        /** @type {boolean | null} */
        this.sad_alo_ind = feature.get('sad_alo_ind')
        /** @type {string} */
        this.sad_city_name = feature.get('sad_city_name')
        /** @type {Date | null} */
        this.sad_com_dt = feature.get('sad_com_dt') && new Date(feature.get('sad_com_dt'))
        /** @type {number} */
        this.sad_gwr_building_egid = feature.get('sad_gwr_building_egid')
        /** @type {string | null} */
        this.sad_house_num = feature.get('sad_house_num')
        /** @type {Date | null} */
        this.sad_isr_dt = feature.get('sad_isr_dt') && new Date(feature.get('sad_isr_dt'))
        /** @type {string} */
        this.sad_municipality_name = feature.get('sad_municipality_name')
        /** @type {number} */
        this.sad_postal_code = feature.get('sad_postal_code')
        /** @type {string | null} */
        this.sad_residence_name = feature.get('sad_residence_name')
        /** @type {string} */
        this.sad_street_name = feature.get('sad_street_name')
        /** @type {Date | null} */
        this.sad_target_dt = feature.get('sad_target_dt') && new Date(feature.get('sad_target_dt'))
        /** @type {number | null} */
        this.sad_target_quality_id = feature.get('sad_target_quality_id')
        /** @type {string} */
        this.scs_site_name = feature.get('scs_site_name')
        /** @type {number | null} */
        this.site_id = feature.get('site_id')
        /** @type {string | null} */
        this.site_name = feature.get('site_name')
        /** @type {string | null} */
        this.site_public_pop_name = feature.get('site_public_pop_name')
        /** @type {Date | null} */
        this.site_service_start = feature.get('site_service_start')
        /** @type {string} */
        this.source = feature.get('source')
        /** @type {string} */
        this.state = feature.get('state')
    }

    getFormattedAddress() {
        return `${this.sad_street_name} ${this.sad_house_num}<br>${this.sad_postal_code} ${this.sad_city_name}`
    }

    getFormattedPopName() {
        let pop_name = this.site_name || this.scs_site_name
        if (this.site_public_pop_name) {
            pop_name += ` - ${this.site_public_pop_name}`
        }
        return pop_name
    }

    isP2P() {
        if (this.sad_alo_ind === null) {
            return this.state === 'alex_na' || this.state === 'f7_rfo' || this.state === 'f7_rfm'
        }

        return this.sad_alo_ind || false;
    }

    getTopology() {
        return this.isP2P() ? 'P2P' : 'P2MP'
    }

    getProduct() {
        let product = PointInfo.productMap[this.state]
        if (['h7_rfo', 'h7_rfm'].includes(this.state)) {
            if (!this.isP2P()) {
                product = PointInfo.productMap[`${this.state}_p2mp`]
            }
        }

        if (['f7_rfm'].includes(this.state) && this.source === 'ready-for-order') {
            product = PointInfo.productMap['h7_now']
            if (!this.isP2P()) {
                product = PointInfo.productMap[`h7_now_p2mp`]
            }
        }

        return product
    }

    getTargetDate() {
        return this.sad_target_dt || this.sad_isr_dt
    }

    getQuality() {
        if (['f7_rfo', 'h7_rfo'].includes(this.state)) {
            return [5, null]
        }

        const targetDate = this.getTargetDate()
        const now = new Date()

        let quality = 0;

        if (this.sad_target_quality_id) {
            quality = PointInfo.qualityMapNumeric[this.sad_target_quality_id]
        } else if (targetDate && targetDate < now) {
            quality = 5
        }

        if (targetDate && targetDate > now) {
            return [quality, targetDate]
        }
        return [quality, null]
    }
}

export class PointInfo {

    static qualityMap = {
        '1': '90%',
        '2': '70%',
        '3': '50%',
        '4': '30%',
        '11': '90%',
        '12': '70%',
        '13': '50%',
        '14': '30%',
    }

    static qualityMapNumeric = {
        '1': 4,
        '2': 3,
        '3': 2,
        '4': 1,
        '11': 4,
        '12': 3,
        '13': 2,
        '14': 1,
    }

    static productMap = {
        'h7_rfo': '<a href="https://www.init7.net/de/internet/weitere-angebote/" target="_blank" rel="noopener">Hybrid7</a>',
        'h7_rfm': '<a href="https://www.init7.net/de/internet/weitere-angebote/" target="_blank" rel="noopener">Hybrid7</a>',
        'h7_rfo_p2mp': '<a href="https://www.init7.net/de/internet/weitere-angebote/" target="_blank" rel="noopener">Hybrid7 P2MP</a>',
        'h7_rfm_p2mp': '<a href="https://www.init7.net/de/internet/weitere-angebote/" target="_blank" rel="noopener">Hybrid7 P2MP</a>',
        'f7_rfo': '<a href="https://www.init7.net/de/internet/fiber7/" target="_blank" rel="noopener">Fiber7</a>, <a href="https://www.init7.net/de/internet/easy7/" target="_blank" rel="noopener">Easy7</a>',
        'f7_rfm': '<a href="https://www.init7.net/de/internet/fiber7/" target="_blank" rel="noopener">Fiber7</a>, <a href="https://www.init7.net/de/internet/easy7/" target="_blank" rel="noopener">Easy7</a>',
        'alex_na': 'N/A',
        'h7_now': '<a href="https://www.init7.net/de/internet/weitere-angebote/" target="_blank" rel="noopener">Hybrid7 (now)</a> <br>Fiber7, Easy7 (soon)',
        'h7_now_p2mp': '<a href="https://www.init7.net/de/internet/weitere-angebote/" target="_blank" rel="noopener">Hybrid7 P2MP (now)</a> <br>Fiber7, Easy7 (soon)',
    }

    constructor(rootElement) {
        this.rootElement = rootElement
        this.bsElement = new bootstrap.Collapse(this.rootElement, {toggle: false})

        this._$address = this.rootElement.querySelector('.f-address')
        this._$pop = this.rootElement.querySelector('.f-pop')
        this._$product = this.rootElement.querySelector('.f-product')
        this._$topology = this.rootElement.querySelector('.f-topology')
        this._$readyDate = this.rootElement.querySelector('.f-ready-date')
        this._$quality = this.rootElement.querySelector('.f-quality-rating')
        this._qualityClassName = this._$quality.className


    }

    updateSidebar(feature) {
        if (!feature) {
            this.bsElement.hide()
            return
        }

        const point = new PointData(feature)

        this._$address.innerHTML = point.getFormattedAddress()
        this._$pop.innerHTML = point.getFormattedPopName()
        this._$product.innerHTML = point.getProduct()
        this._$topology.innerHTML = point.getTopology()

        // const featureState = feature.get('state')

        const [quality, targetDate] = point.getQuality()

        this.rootElement.classList.remove('has-ready-date')
        if (targetDate) {
            this._$readyDate.innerHTML = formatDateYMD(targetDate)
            this.rootElement.classList.add('has-ready-date')
        }

        this._$quality.className = this._qualityClassName + ' ' + 'circ-rating-v' + quality

        this.bsElement.show()
    }

}