import {get as getProjection} from 'ol/proj.js';
import {Point, Polygon} from "ol/geom";
import {createExtent} from "ol/centerconstraint";
import {Extent} from "ol/interaction";
import {boundingExtent} from "ol/extent";

const DEFAULT_PARAMS = {
    'format': 'jsonv2',
    'countrycodes': 'ch',
    'layer': 'address'
}

export default class AddressSearch {
    constructor(nominatimUrl, map, maxZoom) {
        this.nominatimUrl = nominatimUrl
        /** @type {Map} */
        this.map = map
        this.maxZoom = maxZoom

        this.nominatimProj = getProjection('EPSG:4326')
    }

    attachSearchForm(form) {
        form.addEventListener('submit', (event) => {
            event.preventDefault()
            const input = form.querySelector('input[name=search]')
            this.performSearchAndPan(input.value).catch(e => console.error(e))
        })
    }

    async performSearchAndPan(query) {
        const results = await this._callNominatim(query)
        if (results.length === 0) {
            console.log('no result found')
            return false
        }

        const result = results[0]
        const rBBox = result.boundingbox.map(parseFloat)


        const p1 = new Point([rBBox[2], rBBox[0]])
        const p2 = new Point([rBBox[3], rBBox[1]])
        p1.transform(this.nominatimProj, this.map.getView().getProjection())
        p2.transform(this.nominatimProj, this.map.getView().getProjection())

        const bbox = boundingExtent([p1.getCoordinates(), p2.getCoordinates()])

        console.log(bbox)

        this.map.getView().fit(bbox, {
            maxZoom: this.maxZoom,
            duration: 150
        })
    }

    _callNominatim(query) {
        const params = new URLSearchParams(DEFAULT_PARAMS)
        params.append('q', query)

        const url = `${this.nominatimUrl}?${params.toString()}`
        return fetch(url)
            .then(response => response.json())
    }
}
