import VectorSource from "ol/source/Vector";
import {GeoJSON} from "ol/format";
import {bbox as bboxStrategy} from 'ol/loadingstrategy';

export const WFSSource = (serverURL, layerName, projection = 'EPSG:3857', extraParams = {}) => {
    const baseParams = {
        'service': 'WFS',
        'version': '1.1.0',
        'request': 'GetFeature',
        'typename': layerName,
        'outputFormat': 'application/json',
        'srsname': projection,
        ...extraParams,
    }


    return new VectorSource({
        format: new GeoJSON({
            dataProjection: projection,
        }),
        url: function (extent) {
            const params = {
                ...baseParams,
                'bbox': `${extent.join(',')},${projection}`,
            }

            return serverURL + '?' + new URLSearchParams(params).toString();
        },
        strategy: bboxStrategy,
    })
}


export const attachLayerCheckbox = (key, checkbox, link, layer) => {
    const update = (checked) => {
        if (checked) {
            link.update(key, '1')
            layer.setVisible(true);
        } else {
            link.update(key, '0')
            layer.setVisible(false);
        }
    }

    checkbox.addEventListener('change', () => {
        update(checkbox.checked)
    })

    const initialValue = link.track(key, (value) => {
        checkbox.checked = value === '1'
    })
    checkbox.checked = initialValue !== '0'
    update(checkbox.checked)
}